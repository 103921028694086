import React, { useState } from 'react';
import { Form, Button, Modal, Spinner, Container, Row, Col, Alert, InputGroup, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthPostRequest, AuthGetRequest } from '../Backend/APIMiddleware';
import CustomerForm from '../Customers/CustomerForm';

const TicketCreation = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [showCustomerForm, setShowCustomerForm] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [ticketType, setTicketType] = useState('');
    const [isCreatingTicket, setIsCreatingTicket] = useState(false);
    const [customerSearch, setCustomerSearch] = useState('');
    const [error, setError] = useState('');
    const [isSearching, setIsSearching] = useState(false); // New loading state for search button

    const handleCreateCustomer = (newCustomerId) => {
        setSelectedCustomer(newCustomerId);
        setShowCustomerForm(false);
    };

    const toggleCreateModal = () => {
        setShowCustomerForm(!showCustomerForm);
    };

    const handleSearchCustomer = async () => {
        if (!customerSearch) {
            setError(t('Please enter a phone number to search.'));
            return;
        }

        setError('');
        setIsSearching(true); // Set loading state

        try {
            const response = await AuthGetRequest(`/v1/accounts/search?phone=${customerSearch}`);
            if (response.data && response.data.id) {
                setSelectedCustomer(response.data);
            } else {
                setError(t('Customer not found. You can create a new customer below.'));
                setShowCustomerForm(true);
            }
        } catch (error) {
            if (error.response?.status === 404) {
                setError(t('Customer not found. You can create a new customer below.'));
                setShowCustomerForm(true);
            } else {
                console.error('Error searching customer:', error);
                setError(t('Failed to search for customer. Please try again.'));
            }
        } finally {
            setIsSearching(false); // Reset loading state
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearchCustomer();
        }
    };

    const handleCreateTicket = async () => {
        if (!selectedCustomer || !ticketType) {
            setError(t('Please select a customer and ticket type.'));
            return;
        }

        setIsCreatingTicket(true);
        setError('');

        try {
            const response = await AuthPostRequest('/v1/ticket/create', {
                customerID: selectedCustomer.id,
                TicketType: ticketType,
            });
            navigate(`/ticket/view?ticketID=${response.data.ticketID}`);
        } catch (error) {
            console.error('Error creating ticket:', error);
            setError(t('Failed to create ticket. Please try again.'));
        } finally {
            setIsCreatingTicket(false);
        }
    };

    return (
        <Container>
            <h2>{t('Ticket Creation')}</h2>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Row className='mb-4'>
                <Col>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">+1</InputGroup.Text>
                        <FormControl
                            placeholder={t('Search for customer by phone number...')}
                            value={customerSearch}
                            onChange={(e) => setCustomerSearch(e.target.value)}
                            onKeyDown={handleKeyDown} // Trigger search on Enter key press
                        />
                        <Button variant='outline-secondary' onClick={handleSearchCustomer} disabled={isSearching}>
                            {isSearching ? <Spinner as='span' animation='border' size='sm' /> : t('Search')}
                        </Button>
                    </InputGroup>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col>
                    {showCustomerForm && (
                        <CustomerForm show={showCustomerForm} close={toggleCreateModal} setNewUserID={handleCreateCustomer} initialPhone={customerSearch} />
                    )}
                </Col>
            </Row>

            {selectedCustomer && (
                <Row className='mb-4'>
                    <Col>
                        <Alert variant='success'>
                            {t('Selected Account')}: {selectedCustomer.firstName} {selectedCustomer.lastName}
                        </Alert>
                    </Col>
                </Row>
            )}

            {selectedCustomer && (
                <Row className='mb-4'>
                    <Col>
                        <Form>
                            <Form.Group controlId='ticketType'>
                                <Form.Label>{t('Select Ticket Type')}</Form.Label>
                                <Form.Check
                                    type='radio'
                                    label={t('Repair')}
                                    name='ticketType'
                                    value='Repair'
                                    checked={ticketType === 'Repair'}
                                    onChange={(e) => setTicketType(e.target.value)}
                                />
                                <Form.Check
                                    type='radio'
                                    label={t('Sale')}
                                    name='ticketType'
                                    value='Sale'
                                    checked={ticketType === 'Sale'}
                                    onChange={(e) => setTicketType(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            )}

            {selectedCustomer && ticketType && (
                <Row>
                    <Col>
                        <Button variant='primary' onClick={handleCreateTicket} disabled={isCreatingTicket} disabled>
                            {isCreatingTicket ? <><Spinner as='span' animation='border' size='sm' /> {t('Creating Ticket')}</> : t('Create Ticket')}
                        </Button>
                    </Col>
                </Row>
            )}
        </Container>
    );
};

export default TicketCreation;
