import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faB, faBars, faCircleUser, faHome, faSignOut, faUser } from '@fortawesome/free-solid-svg-icons'
//import { useAuth } from '../AuthenticationScreen/AuthContext';
import { getCurrentUser, fetchUserAttributes, fetchAuthSession } from '@aws-amplify/auth'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
    const { t } = useTranslation();

    const { signOut, toggleNav } = props;
    const [user, setUser] = useState({});
    useEffect(() => {
        
        if (signOut) {
            fetchUserAttributes().then((userAttributes) => {
                setUser(userAttributes)
            })
        }
    }, [signOut])

    if (!toggleNav) {
        return (
            <>
                <header style={{ display: 'flex', alignItems: 'center' }}>
                    <div></div>

                    <h3 style={{ margin: '0', backgroundColor: 'transparent' }}><strong>GemRX</strong></h3>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div></div>
                    </div>
                </header>
            </>
        )
    }

    return (

        <header style={{ display: 'flex', alignItems: 'center' }}>
            {signOut && (<>
                <div style={{ padding: '15px', cursor: 'pointer' }} onClick={toggleNav}><FontAwesomeIcon icon={faBars} /></div>
                
                <Link
                        className="app-icon-small centered"
                        to={`/`}
                        style={{  cursor: 'pointer', color: 'white', alignItems: 'center', textDecoration: 'none' }}
                    >
                        <img src="/icon512_maskable.png" alt="App Icon" />
                        <h4 style={{ margin: '0', backgroundColor: 'transparent' }}>
                            <strong>GemRX</strong>
                        </h4>
                    </Link>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                   <small><small> {t('Hi')}, {user.given_name} {user.family_name}</small></small>
                    <FontAwesomeIcon icon={faSignOut} style={{ padding: '20px', cursor: 'pointer' }} onClick={signOut} />
                </div>
            </>)}
            {!signOut && (
                <>
                    <Link style={{  padding: '15px', cursor: 'pointer', color: 'white', alignItems: 'center', textDecoration: 'none' }} to={`/login`} ><FontAwesomeIcon style={{ color: 'white', scale: '150%', paddingRight: '15px' }} icon={faCircleUser} /><small>{t('Login')}</small></Link>
                    {/* <div style={{ padding: '15px', cursor: 'pointer' }} onClick={()=>{window.location.href('/login')}}><FontAwesomeIcon icon={faCircleUser} /></div> */}
                    <Link
                        className="app-icon-small centered"
                        to={`/`}
                        style={{ padding: '15px', cursor: 'pointer', color: 'white', alignItems: 'center', textDecoration: 'none' }}
                    >
                        <img src="/icon512_maskable.png" alt="App Icon" />
                        <h4 style={{ margin: '0', backgroundColor: 'transparent' }}>
                            <b>GemRX</b>
                        </h4>
                    </Link>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Link style={{ padding: '15px', cursor: 'pointer', color: 'white', scale: '150%' }} to={`/`}><FontAwesomeIcon icon={faHome} /></Link>

                    </div>
                </>
            )}

        </header>
    )
}

export default Header;