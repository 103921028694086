import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tabs, Tab, Button, Card, Modal, Spinner, Container, Row, Col, FormControl, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthGetRequest, AuthPostRequest } from '../Backend/APIMiddleware';
import { Form } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';


export const CustomerDetails = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const customerID = queryParams.get('customerID');
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [customerData, setCustomerData] = useState({});
    const [purchases, setPurchases] = useState([]);
    const [repairs, setRepairs] = useState([]);
    const [layaways, setLayaways] = useState([]);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showEditEmailModal, setShowEditEmailModal] = useState(false);
    const [newCustomerEmail, setNewCustomerEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const { credentials, currentUser } = props;

    useEffect(() => {
        // Fetch customer data, purchases, repairs, and layaways using AuthGetRequest
        const fetchCustomerData = async () => {
            setIsLoadingData(true);
            try {
                const customerResponse = await AuthGetRequest(`/v1/accounts?customerID=${customerID}`);
                setCustomerData(customerResponse.data);

                // Dummy data for purchases, repairs, and layaways
                const dummyPurchases = [
                    { transactionID: 'P123', transactionDate: '2023-05-01', employeeName: 'Alice', totalItems: 3, totalAmount: 150 },
                    { transactionID: 'P124', transactionDate: '2023-05-10', employeeName: 'Bob', totalItems: 1, totalAmount: 50 }
                ];
                //setPurchases(dummyPurchases);

                const dummyRepairs = [
                    { transactionID: 'R125', transactionDate: '2023-04-15', employeeName: 'Charlie', totalItems: 1, totalAmount: 200, balanceDue: 0, repairStatus: 'Completed', completionDate: '2023-04-20' }
                ];
                //setRepairs(dummyRepairs);

                const dummyLayaways = [
                    { transactionID: 'L126', transactionDate: '2023-03-01', employeeName: 'David', totalItems: 2, totalAmount: 500, contractStartDate: '2023-03-01', timeRemainingToPay: '30 days', paidFullDate: '2023-04-01' }
                ];
                //setLayaways(dummyLayaways);
            } catch (error) {
                console.error('Error fetching customer data:', error);
            } finally {
                setIsLoadingData(false);
            }
        };

        fetchCustomerData();
    }, [customerID]);

    const handleResetPassword = async () => {
        setIsResettingPassword(true);
        try {
            // Add your password reset API call here
            await AuthPostRequest('/v1/accounts', { customerID });
            alert('Password reset successfully');
        } catch (error) {
            console.error('Error resetting password:', error);
        } finally {
            setIsResettingPassword(false);
        }
    };


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEditEmail = async () => {
        if (!validateEmail(newCustomerEmail)) {
            setEmailError(t('Please enter a valid email address'));
            return;
        }
        setEmailError('');
        try {
            await AuthPostRequest(`/v1/accounts/update-email?customerID=${customerID}&email=${newCustomerEmail}`);
            setCustomerData({ ...customerData, email: newCustomerEmail });
            alert('Email updated successfully');
            setShowEditEmailModal(false);
        } catch (error) {
            console.error('Error updating email:', error);
        }
    };
    const renderCard = (item, type) => (
        <Card className="mb-3" key={item.transactionID}>
            <Card.Header>
                <Row>
                    <Col><strong>{t('Transaction #')}:</strong> {item.transactionID}</Col>
                    <Col className="text-end"><strong>{t('Total')}:</strong> ${item.totalAmount}</Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    <strong>{t('Transaction Date')}:</strong> {item.transactionDate}<br />
                    <strong>{t('Employee')}:</strong> {item.employeeName}<br />
                    <strong>{t('Total Items')}:</strong> {item.totalItems}<br />
                    {type === 'repair' && (
                        <>
                            <strong>{t('Balance Due')}:</strong> ${item.balanceDue}<br />
                            <strong>{t('Repair Status')}:</strong> {item.repairStatus}<br />
                        </>
                    )}
                    {type === 'layaway' && (
                        <>
                            <strong>{t('Contract Start Date')}:</strong> {item.contractStartDate}<br />
                            <strong>{t('Time Remaining to Pay')}:</strong> {item.timeRemainingToPay}<br />
                        </>
                    )}
                </Card.Text>
                <Button variant="primary" onClick={() => alert(`Details for ${type} ${item.transactionID}`)}>{t('View Details')}</Button>
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col><strong>{t('Status')}:</strong> {item.repairStatus || item.layawayStatus || 'Completed'}</Col>
                    <Col className="text-end"><strong>{t('Status Date')}:</strong> {item.completionDate || item.paidFullDate || 'N/A'}</Col>
                </Row>
            </Card.Footer>
        </Card>
    );

    return (
        <Container fluid>
            <Card className="mb-3">
                <Card.Header>
                    <Row>
                        <Col>
                            <h4>{customerData.firstName && (`${customerData.firstName} ${customerData.lastName}`)}</h4>
                        </Col>
                        {credentials.isEmployee && (
                            <Col className="text-end">
                                <Button variant="secondary" onClick={() => setShowModal(true)}>{t('Reset Password')}</Button>
                            </Col>
                        )}
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={8}>
                            <Card.Text>
                                <strong>{t('Account name')}:</strong> {customerData.firstName && (`${customerData.firstName} ${customerData.lastName}`)}<br />
                                <strong>{t('Account Type')}:</strong> {(customerData.isBusiness ? (<>{t('Business')}</>) : (<>{t('Customer')}</>))}<br />
                                <strong>{t('Phone Number')}:</strong> {customerData.phone}<br />
                                <strong>{t('Email')}:</strong> {customerData.email || 'Not on File'}{' '}
                                {(credentials.isEmployee || customerID === currentUser.userId) && (
                                    // <Button variant="link" onClick={() => setShowEditEmailModal(true)}><i className="bi bi-pencil" /></Button>
                                    <><FaEdit onClick={() => setShowEditEmailModal(true)}/></>
                                )}<br />
                                <Form.Check
                                    type="switch"
                                    label={<strong>{t('Email Promotions')}</strong>}
                                    disabled={(!customerData.emailPromotions && customerID !== currentUser.userId) || !customerData.email}
                                    checked={customerData.emailPromotions}
                                    onChange={() => { console.log("disabled") }}
                                />
                            </Card.Text>
                        </Col>
                        <Col md={4}>
                            <Card.Text>
                                <strong>{t('Total Spend')} {customerData.currentYear}:</strong> ${customerData.yearSpend}<br />
                                <strong>{t('Total Savings')} {customerData.currentYear}:</strong> ${customerData.yearSave}
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Tabs defaultActiveKey="purchases" id="customer-details-tabs" className="mb-3">
                <Tab eventKey="purchases" title={t('Purchases')}>
                    {purchases.map((purchase) => renderCard(purchase, 'purchase'))}
                </Tab>
                <Tab eventKey="repairs" title={t('Repairs')}>
                    {repairs.map((repair) => renderCard(repair, 'repair'))}
                </Tab>
                <Tab eventKey="layaways" title={t('Layaway Contracts')}>
                    {layaways.map((layaway) => renderCard(layaway, 'layaway'))}
                </Tab>
            </Tabs>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Reset Password')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isResettingPassword ? (
                        <Spinner animation="border" />
                    ) : (
                        <p>{t('Are you sure you want to reset the password for this customer?')}</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>{t('Cancel')}</Button>
                    <Button variant="primary" onClick={handleResetPassword} disabled={isResettingPassword}>
                        {isResettingPassword ? <Spinner as="span" animation="border" size="sm" /> : t('Reset Password')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditEmailModal} onHide={() => setShowEditEmailModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('Edit Email')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <FormControl
                            placeholder={t('Enter new email')}
                            value={newCustomerEmail}
                            onChange={(e) => setNewCustomerEmail(e.target.value)}
                            isInvalid={!!emailError}
                        />
                        <FormControl.Feedback type="invalid">
                            {emailError}
                        </FormControl.Feedback>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditEmailModal(false)}>{t('Cancel')}</Button>
                    <Button variant="primary" onClick={handleEditEmail}>{t('Submit')}</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={isLoadingData} centered>
                <Modal.Body>
                    <div className='centered'>
                        <h3>{t("Getting Account Information")}</h3>
                    </div>
                    <div className='centered'><Spinner size='xl' /></div>
                </Modal.Body>
            </Modal>
        </Container>
    );
};