import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, Spinner, Alert, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NewAccount } from '../Backend/BackgroundTasks/AccountsTasks';

const CustomerForm = (props) => {
  const { show, close, setNewUserID, initialPhone } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [error, setError] = useState('');
  const [createdId, setID] = useState('');
  const [accountData, setAccountData] = useState({
    firstName: '',
    lastName: '',
    isBusiness: false,
    promotionalEmails: false,
    email: '',
    phone: initialPhone || '+1',
  });
  const [isCreating, setCreate] = useState(false);

  const resetForm = () => {
    setAccountData({
      firstName: '',
      lastName: '',
      isBusiness: false,
      promotionalEmails: false,
      email: '',
      phone: '+1',
    });
  };

  // Validate US phone number (country code +1 and 10 digits)
  const validatePhone = (phone) => {
    const phoneRegex = /^[2-9]\d{2}[2-9]\d{2}\d{4}$/;
    return phoneRegex.test(phone);
};


  // Validate email
  const validateEmail = (email) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);

  // Handle input change
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    setAccountData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' || type === 'switch' ? checked : value,
      ...(name === 'isBusiness' && checked ? { lastName: '' } : {})
    }));
  };

  const [requiredFieldsPresent, setRequiredFieldsPresent] = useState(false);
  useEffect(() => {
    if (accountData.firstName !== '' && validatePhone(accountData.phone)) {
      if (!accountData.isBusiness && accountData.lastName === '') {
        setRequiredFieldsPresent(false);
      } else if (accountData.promotionalEmails && !validateEmail(accountData.email)) {
        setRequiredFieldsPresent(false);
      } else {
        setRequiredFieldsPresent(true);
      }
    }
  }, [accountData]);


  

  const handleSubmit = (event) => {
    event.preventDefault();
    submitAccountInfo(accountData);
  };

  const navigateToCustomer = () => {
    navigate(`/customers/view-customer?customerID=${createdId}`);
  };

  const copyAndClose = () => {
    navigator.clipboard.writeText(createdId)
      .then(() => {
        alert(t('Customer ID copied to clipboard!'));
        setID('');
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  const submitAccountInfo = (Account) => {
    setError('');
    setCreate(true);
    if (accountData.firstName === '') {
      setCreate(false);
      return;
    }
    const FormattedAccount = {
      firstName: accountData.firstName,
      lastName: accountData.lastName,
      isBusiness: accountData.isBusiness,
      email: accountData.email,
      promotionalEmails: accountData.promotionalEmails,
      phone: accountData.phone,
    };
    NewAccount(FormattedAccount).then((response) => {
      if(initialPhone){
        setNewUserID(response.data.id);
        return;
      }
      setID(response.data.id);
      close();
    }).catch((error) => {
      setError(error.response.data);
    }).finally(() => {
      setCreate(false);
    });
  };

  return (
    <>
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <h3>{t('Create Account')}</h3>
        </Modal.Header>
        <Modal.Body>
          {error.length > 1 && <Alert variant='danger'>{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Label>
              <h4 style={{ textAlign: 'center' }}>{t('New')} {accountData.isBusiness ? t('Business') : t('Customer')} {t('Account')}</h4>
            </Form.Label>
            <Form.Group>
              <Form.Label>{t('Business Profile')}?</Form.Label>
              <Form.Check type='switch' checked={accountData.isBusiness} onChange={handleChange} name="isBusiness" />
            </Form.Group>
            <Form.Group>
              <Form.Label>{accountData.isBusiness ? t('Business Name') : t('First Name')}</Form.Label>
              <Form.Control
                required
                type="text"
                name="firstName"
                value={accountData.firstName}
                onChange={handleChange}
              />
            </Form.Group>
            {!accountData.isBusiness && (
              <Form.Group>
                <Form.Label>{t('Last Name')}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="lastName"
                  value={accountData.lastName}
                  onChange={handleChange}
                />
              </Form.Group>
            )}
            <Form.Group>
              <Form.Label>{accountData.isBusiness ? t('Business Phone') : t('Phone')}</Form.Label>
              <InputGroup>
              <InputGroup.Text id="basic-addon1">+1</InputGroup.Text>
              <Form.Control
                required
                type="text"
                name="phone"
                value={accountData.phone}
                onChange={handleChange}
                isInvalid={!validatePhone(accountData.phone)}
              />
              </InputGroup>
              
              <Form.Control.Feedback type="invalid">
                {t('Please enter a valid phone number.')}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('Enrolled in Promotional Emails')}</Form.Label>
              <Form.Check type='switch' checked={accountData.promotionalEmails} onChange={handleChange} name="promotionalEmails" />
            </Form.Group>
            <Form.Group>
              <Form.Label hidden={!accountData.promotionalEmails}>
                {accountData.isBusiness ? t('Business Email') : t('Email')}
              </Form.Label>
              <Form.Control
                hidden={!accountData.promotionalEmails}
                type="email"
                name="email"
                value={accountData.email}
                onChange={handleChange}
                isInvalid={!validateEmail(accountData.email)}
              />
              <Form.Control.Feedback type="invalid" hidden={!accountData.promotionalEmails}>
                {t('Please enter a valid email address.')}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='submit'
            variant='primary'
            onClick={() => { submitAccountInfo(accountData); }}
            disabled={(!requiredFieldsPresent && !isCreating) || isCreating}
          >
            {!isCreating ? t('Create') : <><Spinner size='sm' /> {t('Creating Account')}</>}
          </Button>
          <Button onClick={resetForm} variant='warning'>{t('Clear')}</Button>
          <Button onClick={close} variant='secondary'>{t('Cancel')}</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={!!createdId} onHide={null}>
        <Modal.Header>{t('Account Created')}</Modal.Header>
        <Modal.Body>
          <strong>{accountData.isBusiness ? t('Business Name') : t('First Name')}:</strong> {accountData.firstName} <br />
          {!accountData.isBusiness && <><strong>{t('Last Name')}:</strong> {accountData.lastName}<br /></>}
          <strong>{t('Phone')}:</strong> {accountData.phone}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='info' onClick={navigateToCustomer}>{t('Go to customer profile')}</Button>
          <Button variant='success' onClick={copyAndClose}>{t('Copy Customer ID')}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomerForm;
