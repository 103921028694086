import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Button, Table, Pagination, InputGroup, Modal, Spinner, FormControl } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { AuthGetRequest } from '../Backend/APIMiddleware';
import { getPagedAccounts } from '../Backend/BackgroundTasks/AccountsTasks';
import CustomerForm from './CustomerForm';

const CustomerViewer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Extract query parameters and initialize state
  const queryParams = new URLSearchParams(location.search);
  const initialSearchType = queryParams.get('type') || 'phone';
  const initialSearchTerm = queryParams.get('term') || '';

  // State variables
  const [totalPages, setTotalPages] = useState(0);
  const initialPage = parseInt(queryParams.get('page') || '1', 10);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [pageSize, setPageSize] = useState(10); // Adjustable page size
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [lastItemKey, setLastItemKey] = useState(null);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [searchType, setSearchType] = useState(initialSearchType);
  const [customers, setCustomers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false); // New loading state for search button
  const [customerSearch, setCustomerSearch] = useState('');
  const [error, setError] = useState('');

  const handleSearchCustomer = async () => {
    if (!customerSearch) {
        setError(t('Please enter a phone number to search.'));
        return;
    }

    setError('');
    setIsSearching(true); // Set loading state

    try {
        const response = await AuthGetRequest(`/v1/accounts/search?phone=${customerSearch}`);
        if (response.data && response.data.id) {
            navigateToCustomer(response.data.id);
        } else {
            setError(t('Customer not found. You can create a new customer below.'));
        }
    } catch (error) {
        if (error.response?.status === 404) {
            setError(t('Customer not found. You can create a new customer below.'));

        } else {
            console.error('Error searching customer:', error);
            setError(t('Failed to search for customer. Please try again.'));
        }
    } finally {
        setIsSearching(false); // Reset loading state
    }
};
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        handleSearchCustomer();
    }
};


  // Handle form submission
  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Update the query string
    navigate(`?type=${searchType}&term=${searchTerm}`);
  };

  // Function to call the search API
  const callSearchApi = async () => {
    console.log('Type ', searchType, ' Keyword ', searchTerm);
    AuthGetRequest(`/v1/accounts`);
  };

  // Effect to call API when component mounts or query parameters change
  useEffect(() => {
    if (searchTerm !== '') {
      if (searchTerm.includes('@') || searchTerm.length === 10) {
        callSearchApi();
      }
    }
  }, [searchType, searchTerm, currentPage]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  async function getPagedAccountsScreen() {
    setLoading(true);
    let response = await getPagedAccounts(pageSize, 1);
    console.log(response);
    setCustomers(response.data);
    setTotalPages(response.totalPages);
    setLastItemKey(response.lastItemId);
    setLoading(false);
  }

  useEffect(() => {
    getPagedAccountsScreen();
  }, [currentPage]);

  // Function to render a single row in the table
  const renderCustomerRow = (customer) => (
    <tr key={customer.id}>
      <td onClick={() => { navigateToCustomer(customer.id); }}>{customer.firstName} {customer.lastName}</td>
      <td><a href={`tel:${customer.phone}`}>{customer.phone}</a></td>
    </tr>
  );

  // Function to render pagination
  const renderPagination = () => {
    if (totalPages <= 1) return null;

    return (
      <Pagination>
        <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
        <Pagination.Item active>{currentPage}</Pagination.Item>
        <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
      </Pagination>
    );
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const navigateToCustomer = (selectedID) => {
    navigate(`/customers/view-customer?customerID=${selectedID}`);
  };
  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  return (
    <div>
      <Form as={Row} onSubmit={handleFormSubmit}>
        <h2>{t('Customer Search')}</h2>
        <InputGroup style={{ width: '75%', zIndex: 0 }}>
                        <InputGroup.Text id="basic-addon1">+1</InputGroup.Text>
                        <FormControl
                            placeholder={t('Search for customer by phone number...')}
                            style={{ width: '20%' }}
                            value={customerSearch}
                            onChange={(e) => setCustomerSearch(e.target.value)}
                            onKeyDown={handleKeyDown} // Trigger search on Enter key press
                        />
                        <Button variant='outline-secondary' onClick={handleSearchCustomer} disabled={isSearching}>
                            {isSearching ? <Spinner as='span' animation='border' size='sm' /> : t('Search')}
                        </Button>
                        <Button variant='success' onClick={toggleCreateModal}>
  <span className='d-none d-md-inline'>{t('New Account')}</span>
  <span className='d-md-none'>+</span>
</Button>

                    </InputGroup>
        <Col xs="auto">
          
          {showCreateModal && (<CustomerForm show={showCreateModal} close={toggleCreateModal}/>)}{/**This calls the create account form. */}
        </Col>
      </Form>

      <Table>
        <thead>
          <tr>
            <th>{t('Account Name')}</th>
            <th>{t('Phone Number')}</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && customers.length === 0 && (
            <tr>
              <td><Spinner /></td>
              <td>{t('Getting User Accounts')}</td>
              <td></td>
              <td></td>
            </tr>
          )}
          {customers.map(renderCustomerRow)}
        </tbody>
      </Table>

      {renderPagination()}
    </div>
  );
};

export default CustomerViewer;
